import * as React from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import pose from 'react-pose';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
// import { Link } from '../components/wrapper-lang'
import SEO from '../components/seo';
import { FlexContent } from '../components/grid';
import { H1, Header, Hanger, Layout, SubTitle, } from '../components/template.common';
const EmptyIndex = () => {
    return <h1>No contents in the selected language</h1>;
};
const StyledArticle = styled.article `
  position: relative;
  margin-bottom: 4rem;

  img {
    object-fit: cover;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.fg};
  }

  &:hover ${Hanger} {
    p {
      transform: translateX(0);
    }
  }
`;
const StyledInfo = styled.div `
  position: relative;
  h1 {
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 1.2;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h1 + p {
    font-size: 0.8rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`;
const TRANSITION = {
    ease: 'easeOut',
};
const PoseHeader = pose.div({
    enter: {
        delay: 150,
        opacity: 1,
        transition: TRANSITION,
    },
    exit: {
        opacity: 0,
        transition: TRANSITION,
    },
});
const PoseContent = pose.div({
    enter: {
        delay: 250,
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: -5,
    },
});
const PoseImageContainer = pose.div({
    enter: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
});
const Entry = ({ slug, featureImage, title, excerpt, date, tags }) => {
    return (<FlexContent>
      <StyledArticle>
        <Link to={slug}>
          {featureImage && <PoseImageContainer>
            <GatsbyImage style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
    }} fadeIn={true} 
    // @ts-ignore
    fluid={featureImage.childImageSharp.fluid}/>
          </PoseImageContainer>}
          <PoseContent>
            <StyledInfo>
              <h1>{title}</h1>
              <p>{excerpt}</p>
              <Hanger>
                <p>
                  <strong>{date}</strong>
                </p>
                {tags && tags.length > 0 && <p>{tags.join(' ')}</p>}
              </Hanger>
            </StyledInfo>
          </PoseContent>
        </Link>
      </StyledArticle>
    </FlexContent>);
};
const NoticeIndex = ({ data, pageContext }) => {
    const noticeContents = data.noticeContents.nodes;
    const { title, subTitle, description } = data.noticeIndex.frontmatter;
    if (noticeContents.length === 0) {
        return <EmptyIndex />;
    }
    const { lang } = pageContext;
    return (<Layout>
      <SEO {...{
        title,
        lang,
        description,
    }}/>
      <PoseHeader>
        <Header>
          <FlexContent>
            <H1 dangerouslySetInnerHTML={{
        __html: title,
    }}/>
            {subTitle && <SubTitle>subTitle</SubTitle>}
          </FlexContent>
        </Header>
      </PoseHeader>
      <ul>
        {noticeContents.map(({ frontmatter: { title, date, tags }, fields: { slug, featureImage }, excerpt, }) => (<li key={slug}>
              <Entry {...{
        slug,
        title,
        excerpt,
        date,
        tags,
        featureImage,
    }}/>
            </li>))}
      </ul>
    </Layout>);
};
export default NoticeIndex;
export const pageQuery = graphql `
  query NoticeIndex($lang: String!) {
    noticeIndex: markdownRemark(
      frontmatter: { type: { eq: "page/notice" }, lang: { eq: $lang } }
    ) {
      frontmatter {
        title
        subTitle
      }
    }
    noticeContents: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "content/notice" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        fields {
          slug
          featureImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        frontmatter {
          title
          lang
          date(formatString: "YYYY.MM.DD")
          tags
        }
        excerpt
      }
    }
  }
`;
